import _ from 'lodash-es'
import moment from 'moment'
import {isNetworkError, ServerError} from '@lookout/request'
import {resolveLocaleAndLang} from '@lookout/i18n'
import I18n from '../i18n.js'
import appConfig from '../../config/app-config.yml'
import initSentry from '../services/sentry-helper.js'
import {initMixpanel} from '../services/mixpanel-helper.js'
import initWootric from '../services/wootric-helper.js'
import {borderPatrolHeaders} from '../services/request.js'
import {readConfig, readCache} from '../services/config-service.js'
import {assumeRole, createEntLoginEvent} from '../services/ents-service.js'
import {
  getEnt,
  getFeatures,
  setCachedTenancy,
  cachedTenancy,
  isTenancyCached,
  defaultEntGuid,
  getTenancyByEntGuid,
} from '../services/tenancy-helper.js'
import lkt from '../lkt.js'
import {
  merge,
  resolveC11nConfig,
  can,
  stringifyQuery,
  parseQuery,
  urlJoin,
  urlEntGuid,
  urlPath,
} from './utils.js'
import {isLoggedEnt, saveLoggedEnt} from './session.js'
import {getLocation, replaceLocation, replaceState} from './location-proxy.js'

export const hideAppLoadingProgress = () => {
  const pulse = globalThis.document.querySelector('#root > .progress-pulse')
  if (pulse?.parentNode) pulse.parentNode.removeChild(pulse)
}

function JSONSafeParse(jsonString) {
  try {
    return JSON.parse(jsonString)
  } catch (e) {
    return {}
  }
}

export const maybeRedirectToEntGuid = () => {
  if (urlEntGuid()) return
  const {state, ...query} = parseQuery(getLocation().search)
  if (state) {
    const {redirect_ent_guid, ...otherState} = JSONSafeParse(
      decodeURIComponent(state),
    )
    if (redirect_ent_guid) {
      replaceLocation(
        _.join(
          _.compact([
            urlJoin(
              lkt.config.url_root,
              redirect_ent_guid,
              urlPath(getLocation().pathname),
            ),
            stringifyQuery({
              ...query,
              ...(!_.isEmpty(otherState) && {
                state: JSON.stringify(otherState),
              }),
            }),
          ]),
          '?',
        ),
      )
    }
  }
}

export const maybeRedirectToFeatureBranchEnv = () => {
  // If this is a feature branch URL that has a nested path, we need to redirect
  // browser to the URL that only has feature branch name as it will be
  // properly resolved by CloudFront to the special MES Console app instance
  const [, featureUrlRoot, path] =
    getLocation().pathname.match(/^(\/features\/[a-z0-9-_]+)(\/.*)?/i) || []
  if (path && path !== '/') {
    // We pass deepLinkPath and deepLinkQuery as GET params to be restored by
    // the feature branch app instance
    replaceLocation(
      `${featureUrlRoot}?${stringifyQuery({
        deepLinkPath: path,
        deepLinkQuery: getLocation().search || null,
      })}`,
    )
    return
  }

  // If these params are present, the page request is likely a result of redirect
  // from the main MES app instance to the feature branch app instance.
  const {deepLinkPath, deepLinkQuery} = parseQuery(getLocation().search)
  if (deepLinkPath || deepLinkQuery) {
    replaceState(
      {},
      window.document.title,
      urlJoin(getLocation().pathname, deepLinkPath, deepLinkQuery),
    )
    return
  }

  if (
    // in case users open some non-existent feature we can check up config url_root and throw 404
    // to make sure users aren't confusing with current stage master branch
    // console app loaded from their wrong url
    (featureUrlRoot && featureUrlRoot !== lkt.config.url_root) ||
    // in case if the feature branch build ended up being deployed into a wrong place (like site root `/`)
    !_.startsWith(getLocation().pathname, lkt.config.url_root)
  ) {
    throw new ServerError('Feature branch does not exist', {
      response: {ok: false, status: 404},
    })
  }
}

/*
 * This is the very first AJAX call that application does.
 * Loading config and cache hash values from l4e micro-service to
 * properly setup application before starting.
 */
export const loadConfiguration = async ({options} = {}) => {
  try {
    const [config, cache] = await Promise.all([
      readConfig({options}),
      readCache({options}),
    ])
    lkt.config = {...lkt.config, ...config} // extending possible existing config set by OIC parent app
    lkt.cache = cache
  } catch (error) {
    if (isNetworkError(error))
      // eslint-disable-next-line no-console
      console?.error(
        'Failed to initialize application. The app config could not be loaded.',
      )
    throw error
  }
}

const importLocale = async ({locale, language}) => {
  let translations
  let i18nLocale
  let momentLocale
  try {
    // trying to load full form locale with country (like `en-US`)
    ;({default: translations} = await import(
      `../../config/locales/console/${locale}.yml`
    ))
    i18nLocale = locale
  } catch {
    // if didn't work let's try with just language (like `en`)
    ;({default: translations} = await import(
      `../../config/locales/console/${language}.yml`
    ))
    i18nLocale = language
  }
  try {
    await import(`../../node_modules/moment/locale/${locale.toLowerCase()}.js`)
    momentLocale = locale.toLowerCase()
  } catch {
    await import(`../../node_modules/moment/locale/${language}.js`)
    momentLocale = language
  }
  return {i18nLocale, momentLocale, translations}
}

export const loadTranslations = async () => {
  // HACK: LOOKOUT_I18N_LOCALE is for unit or integration testing (like Cypress), or other edge case debugging scenarios
  const {locale, language} = resolveLocaleAndLang(
    globalThis.LOOKOUT_I18N_LOCALE,
  )
  if (language !== 'en') {
    try {
      const {i18nLocale, momentLocale, translations} = await importLocale({
        locale,
        language,
      })
      I18n.locale = i18nLocale
      I18n.translations = _.merge({}, I18n.translations, translations)
      moment.locale(momentLocale)
    } catch {
      I18n.locale = 'en'
      moment.locale('en')
      // eslint-disable-next-line no-console
      console?.error(
        `Failed to load "${locale}" locale. Falling back to English.`,
      )
    }
  }
}

const importPartnerConfig = async filename => {
  try {
    const {default: config} = await import(
      `../../config/partners/${filename}.yml`
    )
    return config
    // eslint-disable-next-line no-empty
  } catch {}
}

export const loadCustomizations = async (ent = getEnt()) => {
  let partnerCustomizations
  const commercialPartnerName = _.toLower(ent.commercial_partner_name)
  if (commercialPartnerName) {
    let filename = commercialPartnerName

    let partnerConfig = await importPartnerConfig(filename)

    if (!partnerConfig) {
      // Aka wildcard approach, trying to load by namespace if `_` is present. Example: `crowdstrike_sol` will point to `crowdstrike`
      filename = _.head(_.split(filename, '_'))
      partnerConfig = await importPartnerConfig(filename)
    }

    if (partnerConfig) {
      partnerCustomizations = resolveC11nConfig(partnerConfig[filename])
    } else {
      // eslint-disable-next-line no-console
      console?.error(
        `Failed to load "${commercialPartnerName}" partner customizations.`,
      )
    }
  }
  return merge(
    {},
    resolveC11nConfig(appConfig.lookout, ent.features),
    partnerCustomizations,
  )
}

export class TenancyError extends Error {}

export class TenancyNotAvailableError extends TenancyError {
  constructor({targetEntGuid}) {
    super(I18n.t('tenancy.errors.not_available', {ent_guid: targetEntGuid}))
    this.name = 'TenancyNotAvailableError'
  }
}

export class TenancyAssumeRoleError extends TenancyError {
  constructor() {
    super(I18n.t('error.mfa_setup'))
    this.name = 'TenancyAssumeRoleError'
  }
}

const isLicenseAcceptanceRequired = tenancy =>
  getEnt(tenancy).license_needs_acceptance && can('update', 'ents', tenancy)

const logAndCheckTenancy = async ({
  targetEntGuid = defaultEntGuid(),
  showSystemUseNotice,
  showLicenseAcceptance,
  ...args
} = {}) => {
  const tenancy = getTenancyByEntGuid(targetEntGuid)
  if (!tenancy) {
    if (targetEntGuid) throw new TenancyNotAvailableError({targetEntGuid})
    else return
  }

  const features = getFeatures(tenancy) || {}

  const serviceArgs = {
    ...args,
    tenancy,
    // BP headers are based on a singleton tenancy value, which is not relevant in this case of entering into another tenancy
    skipBorderPatrolHeaders: true,
    headers:
      // explicit pass of BP headers because current tenancy is not cached (put into singleton)
      borderPatrolHeaders({entGuid: targetEntGuid}),
  }

  const {status} = await assumeRole(serviceArgs)
  if (status === 'mfa_setup_required' || status === 'mfa_challenge_required')
    throw new TenancyAssumeRoleError()

  if (!isLoggedEnt(targetEntGuid)) {
    const systemNoticeAccepted = await (features.l4e_fedramp
      ? showSystemUseNotice()
      : Promise.resolve())

    await createEntLoginEvent({
      accept: systemNoticeAccepted,
      ...serviceArgs,
    })

    if (systemNoticeAccepted === false) return

    const licenseAccepted = await (isLicenseAcceptanceRequired(tenancy)
      ? showLicenseAcceptance({tenancy})
      : Promise.resolve())

    if (licenseAccepted === false) return

    saveLoggedEnt(targetEntGuid)
  }

  return tenancy
}

export const initTenancyEnvironment = async ({targetEntGuid, ...args}) => {
  if (isTenancyCached(targetEntGuid)) return cachedTenancy()
  let tenancy
  try {
    tenancy = await logAndCheckTenancy({targetEntGuid, ...args})
  } finally {
    setCachedTenancy(tenancy)
  }
  if (cachedTenancy()) {
    lkt.customizations = await loadCustomizations()
    initSentry()
    initMixpanel()
    initWootric()
  }
  return tenancy
}

/*
 * Logs a funny green message into browser console
 * that "Everything is Okay" after application started.
 */
export const logEverythingIsOkay = () =>
  // eslint-disable-next-line no-console
  console?.log?.(
    `%c
    .      ▁▂▃▄▅▆▆▇▇▇▇▇▆▆▅▄▃▂▁
    .  ▁▄▆████████▀▀▀▀▀████████▆▄▁
    . ▟███▀▀▔▔             ▔▔▀▀███▙
    . ███                       ███  Everything
    . ▐██▏    ▁▃▅▆▆▇▇▇▆▆▅▃▁    ▕██▌
    . ▕██▙▂▄▇█████▀▀▀▀▀█████▇▄▁▟██▏
    .  ▜████▀▀▔▔         ▔▔▀▀████▛
    .   ▜█▙                   ▟█▛       Is
    .    ▜█▙    ▁▄▅▆▆▆▅▄▁    ▟█▛
    .     ▜██▄▆███████████▆▄██▛
    .      ▜████▀▔     ▔▀████▛
    .       ▔███▃       ▃███▔          Okay.
    .         ▀███▄▁ ▁▄███▀
    .           ▀▀█████▀▀
    .              ▔▀▔
    `,
    'color: #3DB249;',
  )
