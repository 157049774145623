import _ from 'lodash-es'
import join from 'url-join'
import PropTypes from 'prop-types'
import {
  isOrgTenancy,
  isEntTenancy,
  isOrgEntTenancy,
  isCommercialPartnerTenancy,
} from '../services/tenancy-helper.js'
import lkt from '../lkt.js'
import {Popularity} from '../components/dashboard/gds-param-types.js'
import {getLocation} from './location-proxy.js'

export const guidPattern = '[0-9a-f]{8}(?:-[0-9a-f]{4}){3}-[0-9a-f]{12}'

export const merge = _.partialRight(
  _.mergeWith,
  (obj, src) => (_.isArray(obj) || undefined) && src,
)

const resolveFeatures = (...args) =>
  _.thru(_.compact(_.flatten(args)), entries =>
    _.isObject(_.last(entries))
      ? {featureNames: _.initial(entries), features: _.last(entries)}
      : {featureNames: entries},
  )

const createCheckFeature = (operator, predicate, features) => (result, f) =>
  operator(
    result,
    _.isString(f) && predicate(_.result(features || lkt.features, f)),
  )

// Checks if all feature names conform to a predicate check result.
const andCheckFeatures = (features, featureNames, predicate) =>
  _.reduce(
    featureNames,
    createCheckFeature((x, y) => x && y, predicate, features),
    true,
  )

// Checks if at least one feature name conforms to a predicate check result.
const orCheckFeatures = (features, featureNames, predicate) =>
  _.reduce(
    featureNames,
    createCheckFeature((x, y) => x || y, predicate, features),
    false,
  )

/**
 * Feature gate helper
 * @param  {String} name   Name of the feature
 * @param  {Array}  names  Name of the features
 * @return {Boolean} True if all of the current Ent feature(s)
 * are available
 */
export const featureOn = (...args) => {
  const {featureNames, features} = resolveFeatures(...args)

  return andCheckFeatures(features, _.flatten(featureNames), f => !!f)
}

/**
 * Feature gate helper
 * @param  {String} name   Name of the feature
 * @param  {Array}  names  Name of the features
 * @return {Boolean} True if at least one of the current Ent
 * feature(s) are available
 */
export const featureOrOn = (...args) => {
  const {featureNames, features} = resolveFeatures(...args)

  return orCheckFeatures(features, _.flatten(featureNames), f => !!f)
}

/**
 * Feature gate helper
 * @param  {String} name   Name of the feature
 * @param  {Array}  names  Name of the features
 * @return {Boolean} True if none of the current Ent feature(s)
 * are available
 */
export const featureOff = (...args) => {
  const {featureNames, features} = resolveFeatures(...args)

  return andCheckFeatures(features, _.flatten(featureNames), f => !f)
}

/**
 * Feature gate helper
 * @param  {String} name   Name of the feature
 * @param  {Array}  names  Name of the features
 * @return {Boolean} True if at least one of the current Ent
 * feature(s) aren't available
 */
export const featureOrOff = (...args) => {
  const {featureNames, features} = resolveFeatures(...args)

  return orCheckFeatures(features, _.flatten(featureNames), f => !f)
}

/**
 * RBAC ability helper
 * @param {string} Action (usually create, read, update, or delete)
 * @param {string} resource (i.e. 'policies' or 'admins' etc.)
 * @returns {bool}
 */
export const can = (action, resource, tenancy) => {
  if (!(action && resource))
    throw new Error('The can utility method needs an action and resource')
  const abilities = (tenancy || lkt.tenancy)?.abilities
  return !!(
    abilities &&
    resource in abilities &&
    _.includes(abilities[resource], action)
  )
}

/**
 *  A wrapper over url-join utility. Removes falsy arguments
 *  before join.
 */
export const urlJoin = (...args) => join(..._.map(_.compact(args), _.toString))

export const isAbsoluteUrl = url => _.isNil(url) || !!url.match(/^http/i)

export const toAbsoluteUrl = url =>
  isAbsoluteUrl(url) ? url : urlJoin(getLocation().origin, url)

/**
 * Combines the server URL (absolute or relative) and a given path (or URL) into
 * a server URL (absolute or relative).
 * @param {String} path a path `/my/path` or a URL `http://server.com/my/path`
 */
export const serverUrlFor = path =>
  isAbsoluteUrl(path)
    ? path
    : // If `config.server` is unset app's domain is same to server's.
      // Enforce the combined path to start with `/` to make sure it's not
      // relative to current app's route
      urlJoin(lkt.config.server || '/', path)

// Makes sure the app uses '/' if config.url_root is empty
export const appUrlRoot = () => lkt.config.url_root || '/'

const urlRootWithGuidRE = () =>
  new RegExp(`^(${appUrlRoot()})?/?(${guidPattern})?`, 'i')

// Extracts URL path without Ent Guid
export const urlPath = targetPath =>
  urlJoin('/', targetPath?.replace(urlRootWithGuidRE(), ''))

export const urlEntGuid = () => {
  const [, , entGuid] = getLocation().pathname.match(urlRootWithGuidRE()) || []
  return entGuid
}

export const print = () => {
  globalThis.print()
}

/**
 * Returns true if the app is running with HSM FF on
 */
export const isHSM = () => featureOn('l4e_hsm_proxy')

/**
 * Returns true if the app is running with FIPS FF on
 */
export const isFIPS = tenancy => featureOn('l4e_fips_proxy', tenancy?.features)

/**
 * Returns true if the app is running with LDH FF on
 */
export const isLDH = tenancy => featureOn('l4e_ldh_proxy', tenancy?.features)

/**
 * Returns true if the user is running in a environment which
 * prohibits sensitive data export (FedRAMP, FIPS, LDH etc.)
 */
export const isLockdownEnv = tenancy =>
  featureOn('l4e_fedramp', tenancy?.features) ||
  featureOn('l4e_ui_restrict_external_calls', tenancy?.features) ||
  isFIPS(tenancy) ||
  isLDH(tenancy)

/**
 * Rewrites the URL to a specific proxy based on current app's execution environment.
 * @param  {String} targetUrl An URL to check and rewrite if it satisfies conditions.
 * @return {String}           A new URL to the proxy.
 */
export const toProxyURL = targetUrl => {
  if (_.isNil(targetUrl)) return targetUrl
  try {
    if (isHSM() || isFIPS() || isLDH()) {
      const url = new URL(targetUrl)
      if (_.endsWith(url.hostname, 'cloudfront.net'))
        return serverUrlFor(`/cloudfront${url.pathname}${url.search}`)
      if (_.startsWith(url.hostname, 's3'))
        return serverUrlFor(`/s3-us-west-2${url.pathname}${url.search}`)
    }
    // Add more conditions and rewrite rules here
  } catch (error) {
    if (error.name !== 'TypeError') throw error
  }
  return targetUrl
}

/**
 * Converts CVSS v3 Severity number into the code name
 * Per spec: https://nvd.nist.gov/vuln-metrics/cvss
 */
export const severityName = (value, {preferPending = false} = {}) => {
  if (value == null) return
  if (value < 0) return preferPending ? 'pending' : 'unknown'
  if (value === 0) return preferPending ? 'pending' : 'none'
  if (value > 0 && value < 4) return 'low'
  if (value >= 4 && value < 7) return 'medium'
  if (value >= 7 && value < 9) return 'high'
  if (value >= 9 && value <= 10) return 'critical'
}

/**
 * Converts CVSS v3 Severity code name into number
 * Per spec: https://nvd.nist.gov/vuln-metrics/cvss
 */
export const severityLevel = (value, {compress = true} = {}) => {
  if (value == null) return
  if (value === 'pending') return compress ? -1 : [-1, 0]
  if (value === 'unknown') return -1
  if (value === 'none') return 0
  if (value === 'low') return compress ? 3 : [1, 2, 3]
  if (value === 'medium') return compress ? 5 : [4, 5, 6]
  if (value === 'high') return compress ? 8 : [7, 8]
  if (value === 'critical') return compress ? 10 : [9, 10]
}

// https://source.corp.lookout.com/lookout/message_schemas/blob/master/protobuf/schemas/policy/policy.proto#L35
export const RiskLevel = {
  NONE: 1,
  ADVISORY: 2,
  LOW: 3,
  MEDIUM: 5,
  HIGH: 8,
}

export const RiskLevelName = _.invert(RiskLevel)

export const riskLevels = _.map(_.keys(RiskLevelName), Number)

export const nextRiskLevel = currentLevel =>
  _.find(riskLevels, level => level > currentLevel)

export const popularityBucket = prevalence => {
  if (prevalence >= 50) return Popularity.EXTREMELY_COMMON
  if (prevalence >= 25 && prevalence < 50) return Popularity.VERY_COMMON
  if (prevalence >= 10 && prevalence < 25) return Popularity.COMMON
  if (prevalence >= 5 && prevalence < 10) return Popularity.UNCOMMON
  if (prevalence >= 1 && prevalence < 5) return Popularity.RARE
  return Popularity.VERY_RARE
}

// https://gist.github.com/9point6/6988755
/* eslint-disable no-param-reassign */
export const ipComparator = (a, b) => {
  a = a?.split('.') || []
  b = b?.split('.') || []
  for (let i = 0; i < a.length; i += 1) {
    a[i] = parseInt(a[i], 10)
    b[i] = parseInt(b[i], 10)
    if (a[i] < b[i]) return -1
    if (a[i] > b[i]) return 1
  }
  return 0
}
/* eslint-enable no-param-reassign */

// You can use this when setting a className prop to
// a list of classes without worrying about whether
// the list contains nil values.
export const classes = (...values) =>
  _.filter(_.flatten(values), _.isString).join(' ')

export const bindActionCreators = (reducerMap, dispatch) =>
  _.reduce(
    reducerMap,
    (result, val, type) => ({
      ...result,
      [type]: payload => dispatch({type, payload}),
    }),
    {},
  )

// Removes all empty object, null or undefined attributes.
// This won't remove some falsy values like Boolean `false` or Number 0.
export const cleanObject = object =>
  _.isPlainObject(object)
    ? _.omitBy(
        _.mapValues(object, cleanObject),
        v =>
          (_.isObject(v) && _.isEmpty(v)) ||
          _.isNil(v) ||
          (_.isString(v) && _.trim(v) === ''),
      )
    : _.isArray(object)
      ? _.map(object, cleanObject)
      : object

// A helper to test objects equality for UI data source objects.
export const isCleanEqual = (value, other) =>
  _.isEqual(cleanObject(value), cleanObject(other))

export const mapValuesDeep = (obj, fn) =>
  _.mapValues(obj, (val, key) =>
    _.isPlainObject(val)
      ? mapValuesDeep(val, fn)
      : _.isArray(val)
        ? _.map(val, v => (_.isPlainObject(v) ? mapValuesDeep(v, fn) : v))
        : fn(val, key, obj),
  )

const idRegExp = /[\d]+/g
const guidRegExp = new RegExp(guidPattern, 'g')

export const scrubSensitiveData = str =>
  _.isString(str)
    ? _.reduce(
        [guidRegExp, idRegExp],
        (result, re) => _.replace(result, re, '***'),
        str,
      )
    : str

const defaultReplacer = (key, value) =>
  value != null
    ? _.isArray(value)
      ? value.map(v => ({key, value: v}))
      : {key, value}
    : null

export const stringifyQuery = (params = {}, {encode = true} = {}) =>
  Object.keys(params)
    .reduce(
      (pairs, key) => pairs.concat(defaultReplacer(key, params[key]) || []),
      [],
    )
    .map(({key, value}) =>
      [key, value].map(v => (encode ? encodeURIComponent(v) : v)).join('='),
    )
    .join('&')

const defaultReviver = (key, value) => ({key, value})

/* eslint-disable no-param-reassign */
export const parseQuery = (string = '') => {
  string = string.slice(1 + string.indexOf('?'))

  return string.split('&').reduce((params, pair) => {
    const parts = pair.split('=').map(decodeURIComponent)
    const {key, value} = defaultReviver(...parts) || {}

    if (value != null)
      // eslint-disable-next-line no-prototype-builtins
      params[key] = params.hasOwnProperty(key)
        ? [].concat(params[key], value)
        : value

    return params
  }, {})
}
/* eslint-enable no-param-reassign */

export const isAadSession = location =>
  _.startsWith((location || getLocation()).hostname, 'aad')

/**
 * Returns url query params string with BP destination settings.
 */
export const destinationParams = ({withId = true, withPath = true} = {}) => {
  const location = getLocation()
  let destinationId
  let destinationPath
  if (withId)
    destinationId = isAadSession(location)
      ? featureOn('aad_government')
        ? lkt.config.aadgov_destination_id
        : lkt.config.aad_destination_id
      : lkt.config.destination_id
  if (withPath) {
    const path = location.pathname + location.search
    destinationPath = path !== '/' ? path : undefined
  }
  return stringifyQuery({destinationId, destinationPath})
}

export const c11n = (key, customizations) =>
  _.thru(_.get(customizations || lkt.customizations, key), value => {
    if (_.isObject(value) && !_.isArray(value))
      throw new Error(
        `C11n expected a scalar value but got an object for key: ${key}`,
      )
    if (_.isUndefined(value))
      throw new Error(`C11n value not found for key: ${key}`)
    return value
  })

const matchTenancyType = tenancyType =>
  tenancyType === 'org'
    ? isOrgTenancy()
    : tenancyType === 'org_ent'
      ? isOrgEntTenancy()
      : tenancyType === 'ent'
        ? isEntTenancy()
        : tenancyType === 'commercial_partner'
          ? isCommercialPartnerTenancy()
          : false

export const resolveC11nConfig = (config, features) =>
  config &&
  _.reduce(
    _.castArray(config),
    (
      result,
      {
        tenancyType: tenancyTypeAttr,
        featureOn: featureOnAttr,
        featureOrOn: featureOrOnAttr,
        featureOff: featureOffAttr,
        featureOrOff: featureOrOffAttr,
        ...attrs
      },
    ) =>
      (!tenancyTypeAttr || matchTenancyType(tenancyTypeAttr)) &&
      (!featureOnAttr || featureOn(featureOnAttr, features)) &&
      (!featureOrOnAttr || featureOrOn(featureOrOnAttr, features)) &&
      (!featureOffAttr || featureOff(featureOffAttr, features)) &&
      (!featureOrOffAttr || featureOrOff(featureOrOffAttr, features))
        ? merge(result, attrs)
        : result,
    {},
  )

export const validation = {
  email: {
    pattern:
      '^(?!.*\\.\\.)("(?:\\\\[\\x00-\\x7F]|[^"\\\\])*"|[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~.-]+(?:\\.[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~.-]+)*)@(?:\\[[^\\]]+\\]|[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*)$',
  },
  domain: {
    pattern:
      '^([A-Za-z0-9-]+\\.){0,62}[A-Za-z0-9][A-Za-z0-9-]+\\.[A-Za-z0-9][a-z0-9-]{0,61}[A-Za-z0-9]$',
  },
  tldDomain: {
    pattern: '^[A-Za-z0-9-.]*\\.[A-Za-z0-9-]+$',
  },
  ipv4: {
    pattern:
      '^(?:(?:25[0-5]{1}\\.)|(?:2[0-4]{1}[0-9]{1}\\.)|(?:1{1}[0-9]?[0-9]?\\.)|(?:[0-9]{1}[0-9]?\\.)){3}(?:(?:25[0-5]{1})|(?:2[0-4]?[0-9]?)|(?:1{1}[0-9]?[0-9]?)|(?:[0-9]{1}[0-9]?)){1}$',
  },
  ipv6: {
    pattern:
      '^((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*::((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*|((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4})){7}$',
  },
  macAddress: {
    pattern: '^[0-9a-f]{1,2}([.:-])[0-9a-f]{1,2}(?:\\1[0-9a-f]{1,2}){4}$',
  },
  guid: {
    pattern: `^${guidPattern}$`,
  },
  url: {
    pattern:
      '^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$',
  },
  name: {
    pattern: "^[\\p{L} .'-]+$",
  },
}

export const toPaginationOffsetQuery = ({per_page, page, ...query}) =>
  per_page && page
    ? {
        limit: per_page,
        offset: per_page * (page - 1),
        ...query,
      }
    : query

export const toSortOrderQuery = ({sort_by, order, ...query}) =>
  sort_by && order ? {sort: `${sort_by}:${order}`, ...query} : query

export const CSSPropType = PropTypes.oneOfType([
  PropTypes.object,
  PropTypes.array,
  PropTypes.func,
])

export const userInitials = username => {
  const [first, last] = _.map(_.compact(username?.split(' ')), n => n[0])
  return (first || '') + (last || '')
}

export const createReactContainer = ({className, rootId = 'root'}) => {
  const root = globalThis.document.getElementById(rootId)
  if (root.querySelector(`.${className}`)) return
  const container = globalThis.document.createElement('div')
  container.setAttribute('class', className)
  root.appendChild(container)
  return {root, container}
}

export const importantStyle = style =>
  mapValuesDeep(
    style,
    value => `${_.isNumber(value) ? `${value}px` : value} !important`,
  )

export const downloadCsv = (csvData, fileName) => {
  const blob = new Blob([csvData], {type: 'text/csv'})
  const csvDownloadUrl = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = csvDownloadUrl
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  URL.revokeObjectURL(csvDownloadUrl)
}

export const jsonToCsv = data => {
  if (!data || data.length === 0) {
    return ''
  }

  const headers = _.keys(data[0])
  const csvRows = [headers.map(_.toUpper).join(',')]

  _.forEach(data, item => {
    const values = _.map(headers, header => {
      const value = item[header]
      return _.isArray(value) ? `"${value.join(';')}"` : value
    })
    csvRows.push(values.join(','))
  })

  return csvRows.join('\n')
}
