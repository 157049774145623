import 'core-js/stable/index.js'
import './favicons.js'
import './styles/all.js'
import {isNetworkError} from '@lookout/request'
///////////////
/////////////////////////////////////////////////
//////////
import {
  loadConfiguration,
  hideAppLoadingProgress,
  loadTranslations,
  logEverythingIsOkay,
  maybeRedirectToFeatureBranchEnv,
  maybeRedirectToEntGuid,
} from './lib/init-helper.js'
import {initGlobalStyles} from './styles/init-global-styles.js'
import {createApp} from './lib/init-legacy-helper.js'
import {initChangeTracker} from './lib/change-tracker.js'
import {handleError} from './lib/error-helper.js'
import errorViewHelper from './views/errors/error-view-helper.coffee'

const showNotFound = () => errorViewHelper.notFound({container: '#root'})

const showNoAccessError = () =>
  errorViewHelper.noAccess({container: '#root', showLogout: true})

const showGenericError = () =>
  errorViewHelper.genericError({container: '#root'})

const main = async () => {
  try {
    initGlobalStyles()
    maybeRedirectToFeatureBranchEnv()
    maybeRedirectToEntGuid()
    await loadConfiguration({options: {skipErrorHandling: true}}) // to skip global error handling
    await loadTranslations()
    initChangeTracker()
    createApp()
    logEverythingIsOkay()
  } catch (error) {
    hideAppLoadingProgress()
    if (isNetworkError(error)) {
      // try handle generic errors like 401 or 406 using global error handler
      if (!handleError(error.response.status, error.body)) {
        if (error.response.status === 403) {
          showNoAccessError()
        } else if (error.response.status === 404) {
          showNotFound()
        } else {
          showGenericError()
        }
      }
    } else {
      showGenericError()
      throw error // for logging into Sentry
    }
  }
}

///////////////
/////////////
//////////

main()
