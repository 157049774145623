import _ from 'lodash-es'
import Backbone from 'backbone'
import {c11n, can, guidPattern, merge} from './lib/utils.js'
import {hasPermissions, isOrgTenancy} from './services/tenancy-helper.js'
import {customerSupportUrl} from './services/support-helper.js'

const guidRegExp = new RegExp(`^${guidPattern}$`, 'i')

// get a first allowed route to force navigate users to in case if the app
// is starting at the root (`dashboard`) and it is not allowed
export const resolveStartRoute = () =>
  _.find(
    Backbone.history.handlers,
    ({route: {options}}) =>
      options.name &&
      !_.startsWith(options.name, 'debug') &&
      (!options.predicate || options.predicate()),
  )?.route

export const routePredicate = routeName =>
  _.thru(
    _.find(
      Backbone.history.handlers,
      ({route: {options}}) => options.name === routeName,
    )?.route.options.predicate,
    predicate => !predicate || predicate(),
  )

const options = {
  constraints: {
    entGuid: {
      test: param => _.isNil(param) || guidRegExp.test(param),
    },
  },
  paramsInQS: false,
}

// Research route is an edge case, as it's an entry point into OIC library
// We target nested Research page or Threat Intel Reports page by the `path`
// param, hence the route name is the same for both pages ('research')
export const researchRoutePredicate = () =>
  c11n('research.enabled') &&
  (hasPermissions('full') ||
    hasPermissions('analyst') ||
    hasPermissions('analyst_read_only'))

export const intelReportsRoutePredicate = () =>
  c11n('intel_reports.enabled') && can('read', 'miasma')

const routes = match => {
  match('(:entGuid)', 'dashboard#index', {
    name: 'dashboard',
    ...options,
    predicate: () =>
      isOrgTenancy() || (can('read', 'threats') && can('read', 'devices')),
  })

  match('(:entGuid/)report', 'dashboard#report', {
    name: 'dashboard-report',
    ...options,
    predicate: () =>
      c11n('dashboard.report.enabled') &&
      can('read', 'threats') &&
      can('read', 'devices'),
  })

  match('(:entGuid/)tenants', 'org-ents#index', {
    name: 'org-ents',
    ...options,
    predicate: () => c11n('org_ents_explorer.enabled') && can('read', 'ents'),
  })

  match('(:entGuid/)tenants/:orgEntGuid(/:tab)', 'org-ents#show', {
    name: 'org-ent',
    ...merge({}, options, {
      constraints: {orgEntGuid: guidRegExp, tab: /^[\w-]+$/},
    }),
    predicate: () => c11n('ent_details.enabled') && can('read', 'ents'),
  })

  match('(:entGuid/)tenants/groups', 'org-ents#groups', {
    name: 'org-ents-groups',
    ...options,
    predicate: () =>
      c11n('system.policy_groups.enabled') && can('read', 'policy_groups'),
  })

  match('(:entGuid/)apps', 'apps#apps', {
    name: 'apps',
    ...options,
    predicate: () => c11n('apps_explorer.enabled') && can('read', 'argos'),
  })

  match('(:entGuid/)apps/policies', 'apps#policies', {
    name: 'apps-policies',
    ...options,
    predicate: () =>
      c11n('apps_explorer.custom_policies.enabled') &&
      can('read', 'custom_policy'),
  })

  match('(:entGuid/)apps/denylist', 'apps#denylist', {
    name: 'apps-denylist',
    ...options,
    predicate: () =>
      c11n('apps_explorer.denylist_management.enabled') &&
      can('read', 'blacklist'),
  })

  match('(:entGuid/)apps/:os/:id', 'apps#show', {
    name: 'app',
    ...merge({}, options, {
      constraints: {
        os: /^(android|ios)$/,
        id: /^[\w-]+#?[\w-_]*$/i,
      },
    }),
    predicate: () => c11n('app_details.enabled') && can('read', 'argos'),
  })

  match('(:entGuid/)research*path', 'research#index', {
    name: 'research',
    ...options,
    predicate: () => researchRoutePredicate() || intelReportsRoutePredicate(),
  })

  match('(:entGuid/)devices', 'devices#index', {
    name: 'devices',
    ...options,
    predicate: () => c11n('devices_explorer.enabled') && can('read', 'devices'),
  })

  match('(:entGuid/)devices/unsupported', 'devices#unsupported', {
    name: 'unsupported-devices',
    ...options,
    predicate: () => c11n('integrations.enabled') && can('read', 'mdm'),
  })

  match('(:entGuid/)devices/groups', 'devices#groups', {
    name: 'device-policy-groups',
    ...options,
    predicate: () =>
      c11n('system.device_groups.enabled') && can('read', 'device_groups'),
  })

  match('(:entGuid/)devices/:guid(/:tab)', 'devices#show', {
    name: 'device',
    ...merge({}, options, {constraints: {guid: guidRegExp, tab: /^[\w-]+$/}}),
    predicate: () => c11n('device_details.enabled') && can('read', 'devices'),
  })

  match('(:entGuid/)os-vulns', 'os-vulns#index', {
    name: 'os-vulns',
    ...options,
    predicate: () => c11n('os_vulns_explorer.enabled') && can('read', 'tiqs'),
  })

  match('(:entGuid/)os-vulns/:platform', 'os-vulns#show', {
    name: 'os-vuln',
    ...merge({}, options, {constraints: {platform: /^[\w]+$/}}),
    predicate: () => c11n('os_vuln_details.enabled') && can('read', 'tiqs'),
  })

  match('(:entGuid/)protections(/:tab)', 'protections#index', {
    name: 'protections',
    ...merge({}, options, {constraints: {tab: /^[\w-]+$/}}),
    predicate: () =>
      (c11n('protections.enabled') && can('read', 'product_settings')) ||
      can('read', 'policies'),
  })

  // BEGIN Shortcuts to product settings tabs for Lookout Assistant
  match('(:entGuid/)protections/policies', 'protections#index', {
    name: 'policies',
    ...options,
    predicate: () => c11n('protections.enabled') && can('read', 'policies'),
  })

  match('(:entGuid/)protections/safe-browsing', 'protections#index', {
    name: 'safe-browsing-settings',
    ...options,
    predicate: () =>
      c11n('protections.safe_browsing.enabled') &&
      can('read', 'product_settings'),
  })

  match('(:entGuid/)protections/quarantine', 'protections#index', {
    name: 'quarantine-settings',
    ...options,
    predicate: () =>
      c11n('protections.quarantine.enabled') && can('read', 'product_settings'),
  })

  match('(:entGuid/)protections/smishing', 'protections#index', {
    name: 'smishing-settings',
    ...options,
    predicate: () =>
      c11n('protections.smishing.enabled') && can('read', 'smishing_settings'),
  })
  // END shortcuts

  match('(:entGuid/)enrollment', 'enrollment#index', {
    name: 'enrollment',
    ...options,
    predicate: () =>
      c11n('system.enrollment.enabled') && can('create', 'invites'),
  })

  match('(:entGuid/)enrollment/email', 'enrollment#enrollViaEmail', {
    name: 'enrollment-email',
    ...options,
    predicate: () =>
      c11n('system.enrollment.email.enabled') && can('create', 'invites'),
  })

  match('(:entGuid/)enrollment/code', 'enrollment#enrollViaCode', {
    name: 'enrollment-code',
    ...options,
    predicate: () =>
      c11n('system.enrollment.code.enabled') && can('create', 'invites'),
  })

  match('(:entGuid/)enrollment/invites', 'enrollment#invites', {
    name: 'enrollment-invites',
    ...options,
    predicate: () =>
      c11n('system.enrollment.invites.enabled') &&
      (can('create', 'invites') || can('update', 'invites')),
  })

  match('(:entGuid/)enrollment/settings', 'enrollment#enrollmentSettings', {
    name: 'enrollment-settings',
    ...options,
    predicate: () =>
      c11n('system.enrollment.settings.enabled') && can('update', 'ents'),
  })

  match('(:entGuid/)integrations', 'integrations#connectors', {
    name: 'integrations',
    ...options,
    predicate: () => c11n('integrations.enabled') && can('update', 'mdm'),
  })

  match('(:entGuid/)integrations/:type/redirect', 'integrations#redirect', {
    name: 'integration-redirect',
    ...merge({}, options, {constraints: {type: /^[\w-]+$/}}),
    predicate: () => c11n('integrations.enabled') && can('update', 'mdm'),
  })

  match('(:entGuid/)integrations/:type/:id', 'integrations#connector', {
    name: 'configure-integration',
    ...merge({}, options, {
      constraints: {
        id: new RegExp(`^(${guidPattern})|(\\d+)$`),
        type: /^[\w-]+$/,
      },
    }),
    predicate: () => c11n('integrations.enabled') && can('update', 'mdm'),
  })

  match('(:entGuid/)integrations/:type', 'integrations#connector', {
    name: 'create-integration',
    ...merge({}, options, {constraints: {type: /^[\w-]+$/}}),
    predicate: () => c11n('integrations.enabled') && can('update', 'mdm'),
  })

  match('(:entGuid/)system/account', 'system#account', {
    name: 'account',
    ...options,
    predicate: () => c11n('system.account.enabled') && can('read', 'ents'),
  })

  match('(:entGuid/)system/partner-apps', 'system#partnerApps', {
    name: 'partner-apps',
    ...options,
    predicate: () =>
      c11n('system.partner_apps.enabled') && can('read', 'entity_applications'),
  })

  match('(:entGuid/)system/admins', 'system#admins', {
    name: 'admins',
    ...options,
    predicate: () => c11n('system.admins.enabled') && can('read', 'admins'),
  })

  match('(:entGuid/)system/application-keys', 'system#applicationKeys', {
    name: 'application-keys',
    ...options,
    predicate: () =>
      c11n('system.application_keys.enabled') && can('read', 'ents'),
  })

  match('(:entGuid/)system/external-apis(/:tab)', 'system#externalApis', {
    name: 'external-apis',
    ...merge({}, options, {constraints: {tab: /^[\w-]+$/}}),
    predicate: () =>
      c11n('system.external_apis.enabled') && can('read', 'ents'), // TODO: to review and update ability, maybe need to issue a new one in l4e
  })

  match('(:entGuid/)system/audit-trail', 'system#auditEvents', {
    name: 'audit-events',
    ...options,
    predicate: () => c11n('system.audit_trail.enabled') && can('read', 'metis'),
  })

  match('(:entGuid/)preferences', 'preferences#index', {
    name: 'preferences',
    ...options,
    predicate: () => c11n('preferences.enabled') && can('read', 'preferences'),
  })

  // TODO: Remove in 2025, backward compatibility for saved deep-links to a threat page
  match('(:entGuid/)issues/:guid', 'threats#show', {
    name: 'threat-legacy',
    ...merge({}, options, {constraints: {guid: guidRegExp}}),
    predicate: () => c11n('issue_details.enabled') && can('read', 'threats'),
  })

  match('(:entGuid/)issues(/:tab)', 'threats#index', {
    name: 'issues',
    ...merge({}, options, {constraints: {tab: /^[\w-]+$/}}),
    predicate: () =>
      (c11n('issues_explorer.enabled') && can('read', 'threats')) ||
      (c11n('smishing.enabled') && can('read', 'smishing_alert_events')),
  })

  // BEGIN Shortcuts to product settings tabs for Lookout Assistant
  match('(:entGuid/)issues/threats', 'threats#index', {
    name: 'threats',
    ...options,
    predicate: () => c11n('issues_explorer.enabled') && can('read', 'threats'),
  })

  match('(:entGuid/)issues/web-content', 'threats#index', {
    name: 'web-content-threats',
    ...options,
    predicate: () => c11n('issues_explorer.enabled') && can('read', 'threats'),
  })

  match('(:entGuid/)issues/smishing', 'threats#index', {
    name: 'smishing',
    ...options,
    predicate: () =>
      c11n('smishing.enabled') && can('read', 'smishing_alert_events'),
  })
  // END shortcuts

  match('(:entGuid/)issues/smishing/report', 'threats#smishing_report', {
    name: 'smishing-report',
    ...options,
    predicate: () =>
      c11n('smishing.enabled') && can('read', 'smishing_alert_events'),
  })

  match('(:entGuid/)issues/smishing/:guid', 'threats#smishing_device_events', {
    name: 'smishing-device-events',
    ...options,
    predicate: () =>
      c11n('smishing_device_events.enabled') &&
      can('read', 'smishing_alert_events'),
  })

  match('(:entGuid/)issues/threats/:guid', 'threats#show', {
    name: 'threat',
    ...merge({}, options, {constraints: {guid: guidRegExp}}),
    predicate: () => c11n('issue_details.enabled') && can('read', 'threats'),
  })

  match('(:entGuid/)support', 'debug#support', {
    name: 'customer-support',
    ...options,
    predicate: () => customerSupportUrl(),
  })

  match('(:entGuid/)download*path', 'system#download', options)

  match('(:entGuid/)debug', 'debug#index', {
    name: 'debug',
    ...options,
    predicate: () => true,
  })

  match('(:entGuid/)debug/lib-exports', 'debug#libExports', {
    name: 'debug-lib-exports',
    ...options,
  })

  match('(:entGuid/)debug/assistant', 'debug#assistant', {
    name: 'debug-assistant',
    ...options,
    predicate: () => c11n('assistant.enabled'),
  })

  // Legacy support of L4E root url paths
  match('ent*path', 'debug#redirect')

  match('les*path', 'debug#redirect')

  // Catch for 404 routes with entGuid set, (preserving entGuid for 404 page)
  match('(:entGuid/)*notFound', 'debug#notFound', options)

  // This should catch all unmatched/404 routes, including ones with invalid entGuid
  match('*notFound', 'debug#notFound')
}

// remove all optional params like (:entGuid/)
const trimPath = routePath => {
  let path = _.replace(routePath, /\(\/?:[^/)]+\/?\)/g, '/')
  if (path !== '/') path = _.trimEnd(path, '/')
  return path
}

// extract all allowed to current user Chaplin routes
export const userAllowedRoutes = () => {
  const result = []
  routes((routePath, c, {name, predicate} = {}) => {
    if (name && predicate && predicate()) {
      const [path, wildcard] = _.split(trimPath(routePath), '*')
      result.push({name, path, wildcard})
    }
  })

  return result
}

export default routes
